import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import theme from '../utils/theme';
import chevronDownWhite from '../../static/img/chevron_header_white.svg';
import chevronDown from '../../static/img/chevron_header.svg';
import chevronUp from '../../static/img/chevron_header_green.svg';
import params from '../utils/params';

const MainDiv = styled.div`
  display: inline;
  position: relative;

  @media (${params.mediaQueryMaxWidth1440}) {
    margin-right: 24px;
  }
`;

const Button = styled.span`
  cursor: pointer;
  position: relative;
  &:hover ~ div {
    pointer-events: auto;
    opacity: 1;
  }

  &:hover ~ .chevron:after {
    content: url(${chevronUp});
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    &:hover ~ .chevron:after {
      content: url(${chevronDown});
    }
  }
`;

const Menu = styled.div`
  background: white;
  overflow: hidden;
  color: ${theme.textBlack};
  transition: opacity 300ms ease-out;

  ul li {
    display: block;
    text-align: left;
    padding-top: 5px;
  }

  a {
    color: var(--text-black);
  }
`;

const HoverMenu = styled(Menu)`
  border-radius: 8px;
  position: absolute;
  top: 30px;
  left: -75px;
  width: 284px;
  z-index: 10;
  box-shadow: 0 42px 60px -20px rgba(38, 38, 44, 0.2);
  opacity: 0;
  pointer-events: none;

  &:hover {
    pointer-events: auto;
    opacity: 1;
  }
  &:hover + .chevron:after {
    content: url(${chevronUp});
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    display: none;
  }
`;

const ClickMenu = styled(Menu)`
  margin-top: 8px;

  ul li {
    font-size: 14px;
    line-height: 17px;
  }

  p {
    font-weight: bold;
    margin-bottom: 0;
  }

  @media (${params.mediaQueryMinWidth1440}) {
    display: none;
  }
`;

const ClickChevron = styled.span`
  position: absolute;
  top: 5px;
  right: -22px;
  cursor: pointer;

  &:after {
    content: url(${({menuOpen}) => (menuOpen ? chevronUp : chevronDown)});
  }

  @media (${params.mediaQueryMinWidth1440}) {
    display: none;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    top: 0;
    right: -16px;
  }
`;

const HoverChevron = styled.span`
  position: absolute;
  right: -14px;
  top: -4px;

  &:after {
    content: url(${({url}) => url});
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  margin: 0 !important;
  text-align: left;
  padding: 16px 24px;
  
  &:hover {
    background: ${theme.primaryLight};
    color: ${theme.textWhite} !important;
  }
  
  &:active {
    background: ${theme.primaryClick};
  }
  
  @media (${params.mediaQueryMaxWidth1440}) {
    padding: 16px 80px;
  }
  
  @media (${params.mediaQueryMaxWidthLarge}) {
    padding: 16px 32px;
  }
}
`;

const Card = ({heading, menuItems, isTransparent}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <MainDiv>
      <Button isTransparent={isTransparent} onClick={() => setMenuOpen(!menuOpen)}>
        {heading}
      </Button>
      <ClickChevron menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
      <HoverMenu className="dropdown-menu">
        {menuItems.map(({to, name, sublinks}) => (
          <StyledLink key={name} to={to}>
            <h3>{name}</h3>
            <ul>
              {sublinks.map(link => (
                <li key={link}>{link}</li>
              ))}
            </ul>
          </StyledLink>
        ))}
      </HoverMenu>
      {menuOpen && (
        <ClickMenu className="dropdown-menu">
          {menuItems.map(({to, name, sublinks}) => (
            <StyledLink key={name} to={to}>
              <p>{name}</p>
              <ul>
                {sublinks.map(link => (
                  <li key={link}>{link}</li>
                ))}
              </ul>
            </StyledLink>
          ))}
        </ClickMenu>
      )}
      <HoverChevron className="chevron" url={isTransparent ? chevronDownWhite : chevronDown} />
    </MainDiv>
  );
};

export default Card;
