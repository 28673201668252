const mainTheme = {
  textBlack: '#26262c',
  textWhite: '#ffffff',
  primaryDark: '#284846',
  primaryLight: '#40b16d',
  primaryHover: '#208256',
  primaryClick: '#2e605c',
  primaryBlue: '#f6f8f9',
  secondaryRed: '#f1663c',
  secondaryPink: '#e8d3da',
  secondaryBlue: '#9bbdca',
  seconadryYellow: '#fcc14d',
};

export default mainTheme;
