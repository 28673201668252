let PORTAL_URLS_PROMISE;

export const getPortalUrls = async () => {
  if (!!PORTAL_URLS_PROMISE) {
    return PORTAL_URLS_PROMISE;
  }

  let response = null;
  await fetch('/config/server.json').then(r => {
    response = r.json();
  });
  PORTAL_URLS_PROMISE = response;
  return response;
};

const api = {
  fetch: async function(url, options) {
    let apiBaseUrl = await getPortalUrls();
    apiBaseUrl = apiBaseUrl.API_URL;
    //for local development one can comment out the following clause
    if (apiBaseUrl !== url.substr(0, apiBaseUrl.length)) {
      url = apiBaseUrl + url;
    }

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    options = Object.assign(
      {},
      {
        method: 'get',
        headers: headers,
      },
      options || {}
    );

    options.method = options.method.toLowerCase();

    if ((options.method === 'post' || options.method === 'put') && options.body !== undefined) {
      options.body = JSON.stringify(options.body);
    }

    let request = await fetch(url, options);
    let response = null;
    let throwError = null;
    try {
      let contentType = request.headers.get('Content-Type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        response = await request.json();
      } else {
        response = await request.text();
      }
      switch (request.status) {
        case 200:
          break;
        default:
          throwError = response || request.statusText;
          break;
      }
    } catch (error) {
      throwError = error;
    }

    if (throwError) {
      if (typeof throwError === 'string') {
        throwError = {message: throwError};
      }
      throw throwError;
    }

    return response;
  },

  get: function(url) {
    return this.fetch(url, {method: 'get'});
  },

  post: function(url, data) {
    return this.fetch(url, {method: 'post', body: data});
  },

  put: function(url, data) {
    return this.fetch(url, {method: 'put', body: data});
  },

  delete: function(url, data) {
    return this.fetch(url, {method: 'delete', body: data});
  },
};

export default api;
