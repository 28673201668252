const params = {
  mediaQueryMaxWidthSmall: 'max-width: 320px',
  mediaQueryMaxWidthLarge: 'max-width: 767px',
  mediaQueryMaxWidth1024: 'max-width: 1023px',
  mediaQueryMaxWidth1440: 'max-width: 1439px',
  mediaQueryMaxWidth2000: 'max-width: 1999px',
  mediaQueryMinWidth1440: 'min-width: 1440px',
};

export default params;
