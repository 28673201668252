import {withPrefix} from 'gatsby';

export const flattenQueryResults = data => {
  const results = [];

  if (!data || !data.edges) return results;

  for (let i = 0; i < data.edges.length; i++) {
    if (data.edges[i] && data.edges[i].node) {
      results.push(data.edges[i].node);
    }
  }
  return results;
};

export const filterByTypeSortByOrder = (edges, type) =>
  edges
    .filter(edge => edge.node.frontmatter.type === type)
    .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order);

export const isProperNumber = number => {
  number = parseInt(number, 10);
  return number === parseInt(number, 10);
};

export const addThousandSeparator = (number, separator = ',') =>
  isProperNumber(number)
    ? number.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1' + separator)
    : number;

export const createMillionsLabel = (number, thousandSeparator) =>
  isProperNumber(number) ? toMillions(number, thousandSeparator) : number;

const toMillions = (number, thousandSeparator) =>
  Math.abs(Number(number)) >= 1.0e6
    ? Math.abs(Number(number)) / 1.0e6 + ' M'
    : addThousandSeparator(number, thousandSeparator);

export function hexToRgb(hex, opacity = 1) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${opacity})
     `
    : null;
}

export const windowObject =
  typeof window !== 'undefined' ? window : {location: {pathname: withPrefix('/'), search: ''}};
