import React from 'react';
import {Location} from '@reach/router';
import Nav from './nav';
import styled from 'styled-components';

const LargeNav = props => {
  return (
    <Container>
      <Nav {...props} />
    </Container>
  );
};

export default props => (
  <Location>{locationProps => <LargeNav {...props} {...locationProps} />}</Location>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    margin: 0 auto;
  }

  ul {
    text-align: center;
  }

  li {
    display: inline;
    position: relative;
  }

  .triangle {
    position: absolute;
    top: 26px;
    left: calc(50% - 8px);
    pointer-events: none;
  }

  a {
    text-decoration: none;
    margin: 0 21px;
    white-space: nowrap;
    font-size: 16px;
    color: inherit;
  }
  a:hover {
    color: inherit;
  }

  span {
    margin: 0 21px;
    padding-bottom: 16px;
    white-space: nowrap;
    font: inherit;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }

  @media (max-width: 1439px) {
    display: none;
  }
`;
