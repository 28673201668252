import React from 'react';
import Card from './dropCard';
import triangle from '../../static/img/triangle_header.svg';
import {Link} from 'gatsby';
import styled from 'styled-components';
import theme from '../utils/theme';
import params from '../utils/params';

export default ({navItems, transparent, configurableLinks, location}) => {
  const isCurrentPath = path => location.pathname.includes(path);

  return (
    <>
      <nav>
        <ul>
          {navItems.map(({title, links}, idx) => {
            if (title) {
              return (
                <li key={idx}>
                  <Card heading={title} menuItems={links} isTransparent={transparent} />
                  {isCurrentPath(title.toLowerCase()) && (
                    <img src={triangle} alt="active-page" className="triangle" />
                  )}
                </li>
              );
            } else {
              return links.map(link => {
                if (link.to[0] === '/') {
                  return (
                    <li key={link.name}>
                      <Link to={link.to}>{link.name}</Link>
                      {isCurrentPath(link.name.toLowerCase()) && (
                        <img src={triangle} alt="active-page" className="triangle" />
                      )}
                    </li>
                  );
                } else {
                  const outboundLink = configurableLinks[link.to];
                  return (
                    <li key={link.name}>
                      {outboundLink ? (
                        <a href={outboundLink}>{link.name}</a>
                      ) : (
                        <Link to="/404">{link.name}</Link>
                      )}
                    </li>
                  );
                }
              });
            }
          })}
        </ul>
      </nav>
      <ActionButtons className="actions-container">
        <a href={configurableLinks['PORTAL_URL'] + '/sign-in'}>Log in</a>
        <SignupElement href={configurableLinks['PORTAL_URL'] + '/sign-up'}>Sign up</SignupElement>
      </ActionButtons>
    </>
  );
};

const SignupElement = styled.a`
  float: right;
  width: 105px;
  height: 40px;
  border-radius: 20px;
  background: ${theme.primaryLight};
  color: ${theme.textWhite} !important;
  padding-top: 10px;
  text-align: center;
  transition: 100ms;
  &:hover {
    cursor: pointer;
    color: ${theme.textWhite} !important;
    background: ${theme.primaryHover};
  }
  &:active {
    background: ${theme.primaryClick};
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;

  @media (${params.mediaQueryMaxWidth1024}) {
    margin: 40px 0 0 195px;
  }
`;
