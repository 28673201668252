import React from 'react';
import styled from 'styled-components';
import Navbar from './navbar';
import '../../static/css/general.css';
import Footer from '../Components/footer';
import {getPortalUrls} from '../utils/api';
import params from '../utils/params';

const Container = styled.div`
  padding-top: ${({withPadding}) => (withPadding ? '72px' : '0')};
  @media only screen and (${params.mediaQueryMaxWidthLarge}) {
    h1 {
      font-size: 34px;
      line-height: 34px;
    }
  }
`;

const Content = styled.div`
  min-height: calc(100vh - 470px);
`;

const Layout = ({withPadding = true, children}) => {
  const [config, setConfig] = React.useState(null);
  React.useEffect(() => {
    if (!config) {
      getPortalUrls().then(response => setConfig(response));
    }
  });

  return (
    <Container withPadding={withPadding}>
      <Navbar configurableLinks={config || {}} />
      <Content>{children}</Content>
      <Footer configurableLinks={config || {}} />
    </Container>
  );
};
export default Layout;
