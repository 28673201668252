import React from 'react';
import styled from 'styled-components';
import {graphql, StaticQuery, Link} from 'gatsby';
import {Location} from '@reach/router';
import theme from '../utils/theme';
import logoDark from '../../static/img/geodatahub_logo.svg';
import logoDarkSmall from '../../static/img/geodatahub_logo_2_colour_light_background.svg';
import logoWhite from '../../static/img/geodatahub_logo_dark_background.svg';
import logoWhiteSmall from '../../static/img/geodatahub_logo_2_colour_dark_background.svg';
import LargeNav from './largeNav';
import SmallNav from './smallNav';
import {flattenQueryResults, windowObject} from '../utils/utils';
import params from '../utils/params';

const NavBar = ({data, location, configurableLinks}) => {
  const [pagePosition, setPagePosition] = React.useState(windowObject.pageYOffset || null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleClick = () => setMenuOpen(!menuOpen);
  const isHomePageTop = pagePosition === 0 && location.pathname === '/';
  const navItems = flattenQueryResults(data.allHeaderLinksJson);
  React.useEffect(() => {
    if (pagePosition === null) {
      setPagePosition(windowObject.pageYOffset);
    }
    const handleScroll = () => {
      setPagePosition(windowObject.pageYOffset);
    };
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  });
  React.useEffect(() => {
    const handleResize = event => {
      if (event.currentTarget.innerWidth >= 1440) {
        setMenuOpen(false);
      }
    };
    windowObject.addEventListener('resize', handleResize);
    return () => windowObject.removeEventListener('resize', handleResize);
  });
  React.useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  });

  return (
    <Container transparent={isHomePageTop} menuOpen={menuOpen}>
      <Content>
        <MenuItemsContainer>
          <Logo
            to="/"
            largecontent={menuOpen ? logoDark : isHomePageTop ? logoWhite : logoDark}
            smallcontent={menuOpen ? logoDarkSmall : isHomePageTop ? logoWhiteSmall : logoDarkSmall}
          />
          <Button onClick={handleClick} menuOpen={menuOpen} transparent={isHomePageTop}>
            {menuOpen ? 'Close' : 'Menu'}
            <Cross menuOpen={menuOpen} />
          </Button>
        </MenuItemsContainer>
        <LargeNav
          navItems={navItems}
          transparent={isHomePageTop}
          configurableLinks={configurableLinks}
        />
        <SmallNav
          navItems={navItems}
          transparent={isHomePageTop}
          location={location}
          configurableLinks={configurableLinks}
          menuOpen={menuOpen}
        />
      </Content>
    </Container>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allHeaderLinksJson {
          edges {
            node {
              title
              links {
                name
                to
                sublinks
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Location>{locationProps => <NavBar data={data} {...locationProps} {...props} />}</Location>
    )}
  />
);

const Container = styled.div`
  overflow: visible;
  height: ${({menuOpen}) => (menuOpen ? '100vh' : '72px')};
  width: 100%;
  z-index: 10;
  padding: 16px 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({menuOpen, transparent}) =>
    menuOpen ? theme.textWhite : transparent ? 'transparent' : theme.textWhite};
  color: ${({menuOpen, transparent}) =>
    menuOpen ? theme.textBlack : transparent ? theme.textWhite : theme.textBlack};
  transition: background-color 200ms ease-out;
`;

const Content = styled.div`
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1200px;
  }

  @media (${params.mediaQueryMinWidth1440}) {
    flex-direction: row;
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    align-items: flex-start;
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (${params.mediaQueryMinWidth1440}) {
    width: auto;
  }
`;

const Logo = styled(Link)`
  width: 143px;
  z-index: 10;

  &:before {
    content: url(${({largecontent}) => largecontent});
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    margin-top: 0;
    width: 105px;
    &:before {
      content: url(${({smallcontent}) => smallcontent});
    }
  }
`;

const Button = styled.button`
  position: relative;
  border: none;
  margin: 12px 12px 0 0;
  background: none;
  color: ${({menuOpen, transparent}) =>
    menuOpen ? theme.textBlack : transparent ? theme.textWhite : theme.textBlack};
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (${params.mediaQueryMinWidth1440}) {
    display: none;
  }
`;

const Cross = styled.span`
  &:before,
  &:after {
    position: absolute;
    right: -6px;
    top: 4px;
    content: ' ';
    height: 15px;
    width: ${({menuOpen}) => (menuOpen ? '2px' : '3px')};
    background-color: var(--primary-light);
  }
  &:before {
    transform: ${({menuOpen}) => (menuOpen ? 'rotate(45deg)' : 'none')};
  }
  &:after {
    transform: ${({menuOpen}) => (menuOpen ? 'rotate(-45deg)' : 'none')};
  }
`;
