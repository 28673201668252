import React from 'react';
import styled from 'styled-components';
import Nav from './nav';
import theme from '../utils/theme';
import params from '../utils/params';

export default props => {
  return (
    <Container menuOpen={props.menuOpen}>
      {props.menuOpen && (
        <Nav {...props} transparent={props.menuOpen ? false : props.transparent} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0 0 140px;
  height: calc(100% - 50px);
  align-items: flex-start;
  flex-direction: column;
  justify-content: ${({menuOpen}) => (menuOpen ? 'flex-start' : 'flex-end')};
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.primaryLight};
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to right,
      transparent 1px,
      rgba(38, 38, 44, 0.25) 2px,
      transparent 2px
    );
  }

  nav .triangle {
    visibility: hidden;
  }

  li,
  a {
    font-family: Gilroy, sans-serif;
    text-decoration: none;
    font-size: 34px;
    margin: 33px 0;
  }
  a:hover {
    color: ${theme.textBlack};
  }

  .actions-container a,
  .dropdown-menu ul li {
    font-family: Lato, sans-serif;
    font-size: 16px;
    margin: 0;
  }

  .actions-container {
    margin: 20px 0 0;
    width: 180px;
    justify-content: space-between;
  }

  li div span img {
    margin-left: 12px;
  }

  .dropdown-menu {
    top: 40px;
    left: -60px;
  }

  .chevron {
    top: 4px;
    right: -20px;
  }

  @media (${params.mediaQueryMinWidth1440}) {
    display: none;
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    margin-left: 100px;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    margin-left: 0;

    li,
    a {
      font-family: Lato, sans-serif;
      text-decoration: none;
      font-size: 18px;
      margin: 16px 0;
    }

    .dropdown-menu {
      left: 0;
      top: 35px;
    }

    .chevron {
      top: 0;
      right: -16px;
    }
  }
`;
