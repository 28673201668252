import React from 'react';
import styled from 'styled-components';
import theme from '../utils/theme';
import GeoDataHubLight from '../../static/img/geodatahub_logo_light.svg';
// import FaceBookLink from '../../static/img/facebook_icon.svg';
// import LinkedinLink from '../../static/img/linkedin_icon.svg';
import Partners from '../../static/img/partners.png';
import PartnersSmall from '../../static/img/partner_placegolder.png';
import {graphql, Link, StaticQuery} from 'gatsby';
import {flattenQueryResults} from '../utils/utils';
import params from '../utils/params';

const FooterSection = styled.footer`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  background: ${theme.primaryDark};
  color: ${theme.textWhite};
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0 10% 0 10%;
  position: relative;

  @media (max-width: 480px) {
    div:empty {
      display: none;
    }
  }
`;

const FooterRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  }
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  
  @media (${params.mediaQueryMaxWidth2000}) {
    max-width: 1100px;
  }

  @media (${params.mediaQueryMaxWidth1440}) {
    max-width: 900px;
    align-items: flex-start;
  }

  @media (${params.mediaQueryMaxWidth1024}) {
    max-width: 700px;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    max-width: 280px;
  }
  
  > div:nth-child(1) {
    margin-bottom: 70px;
    margin-top: 40px;
  }
  > div:nth-child(2) {
    margin-bottom: 40px;
    margin-top: 40px;
    margin: 0;
    display: 'flex';
    flex-direction: 'row';
    width: 100%;
  }
  > div:nth-child(3) {
    height: 40px;
    align-items: flex-end;
    font-size: 14px;
      > div:nth-child(1) {
       width: 30%;
      }
    }
   
    display: 'flex';
    flex-direction: 'row';
  }

    ${FooterRow}:last-child {
      height: 120px;
  }
`;

const ItemContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
   @media (${params.mediaQueryMaxWidth1024}) {
    width: 50%;
    padding 0 20px 0 0;
  }
  
   @media (${params.mediaQueryMaxWidthLarge}) {
    width: 100%;
    margin: 50px 0 60px 0;
  }
`;

const FooterItem = styled.div`
  width: 25%;
  padding 0 0 56px 0;

  a {
    font-size: 16px;
  }
  
  @media (${params.mediaQueryMaxWidth1024}) {
    width: 50%;
  }
`;

const FooterTitle = styled.div`
  font-family: Gilroy;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${theme.primaryLight};
  height: 38px;
  font-size: 16px;
`;

const FooterLink = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  > a {
    color: ${theme.textWhite};
    text-decoration: none;
  }
  .lv_flag_icon::before,
  .est_flag_icon::before {
    position: relative;
    top: 2px;
  }
`;

const FooterContacts = styled.div`
  width: 30%;
  display: ${props => (props.show ? 'block' : 'none')};
  > div {
    a {
      margin-left: 0;
      padding-left: 0 !important;
    }
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    width: 100%;
  }
  
  @media (${params.mediaQueryMaxWidth1024}) {
    padding 0 20px 20px 0p;
  }
  
`;

const Logo = styled(Link)`
  margin: 0 !important;
  
  img {
    margin-top: -12px;
  }
  
   @media (${params.mediaQueryMaxWidth1024}) {
    width: 100%;
    padding 0 20px 0 0;
  }
`;

const ProductCopyRight = styled.div`
  margin-bottom: 30px;

  @media (${params.mediaQueryMaxWidth1024}) {
    margin: 20px 0 20px 0;
  }
`;

const PartnersLogo = styled.div`
  margin-right: 21px;

  @media (${params.mediaQueryMaxWidth2000}) {
    width: 362px;
    align-self: end;
  }

  @media (${params.mediaQueryMaxWidthLarge}) {
    width: 152px !important;
    height: 122px !important;
    position: absolute;
    margin-right: 0;
    bottom: 60px;
    left: 0;
  }
`;

const Footer = ({data, configurableLinks}) => {
  const footerItems = flattenQueryResults(data.allFooterLinksJson);
  const contacts = flattenQueryResults(data.allContactsJson).pop();

  let [innerWidth, setWidth] = React.useState(2000);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  });

  return (
    <FooterSection>
      <FooterContainer>
        <FooterRow>
          <Logo to="/">
            <img src={GeoDataHubLight} alt="GeoDataHub logo" width={190} />
          </Logo>
        </FooterRow>
        <FooterRow>
          <FooterContacts show={contacts}>
            <FooterLink>{contacts.email}</FooterLink>
            <FooterLink>
              <a className="est_flag_icon" href={'tel://' + contacts.phone}>
                {contacts.phone}
              </a>
            </FooterLink>
            <FooterLink>
              <a className="lv_flag_icon" href={'tel://' + contacts.phone_lv}>
                {contacts.phone_lv}
              </a>
            </FooterLink>
            {/*<FooterLink>*/}
            {/*  <span>*/}
            {/*    <a href={contacts.facebook}>*/}
            {/*      <img src={FaceBookLink} alt="Facebook" />*/}
            {/*    </a>*/}
            {/*  </span>*/}
            {/*  <span style={{marginLeft: '15px'}}>*/}
            {/*    <a href={contacts.linkedin}>*/}
            {/*      <img src={LinkedinLink} alt="Linkedin" />*/}
            {/*    </a>*/}
            {/*  </span>*/}
            {/*</FooterLink>*/}
          </FooterContacts>

          <ItemContainer>
            {footerItems.map((item, idx) => {
              return (
                <FooterItem key={idx}>
                  <FooterTitle>{item.title}</FooterTitle>
                  {item.links.map((link, idx) => {
                    if (link.to[0] === '/') {
                      return (
                        <FooterLink key={idx}>
                          <a href={link.to}>{link.name}</a>
                        </FooterLink>
                      );
                    } else {
                      const outboundLink = configurableLinks[link.to];
                      if (!outboundLink) {
                        return null;
                      }
                      return (
                        <FooterLink key={idx}>
                          <a href={configurableLinks[link.to]}>{link.name}</a>
                        </FooterLink>
                      );
                    }
                  })}
                </FooterItem>
              );
            })}
          </ItemContainer>
        </FooterRow>
        <FooterRow>
          <ProductCopyRight>
            {contacts.name}&nbsp;&copy;&nbsp;{contacts.year}
          </ProductCopyRight>
          <PartnersLogo>
            <img src={innerWidth > 767 ? Partners : PartnersSmall} alt="Partners" />
          </PartnersLogo>
        </FooterRow>
      </FooterContainer>
    </FooterSection>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allFooterLinksJson {
          edges {
            node {
              title
              links {
                name
                to
              }
            }
          }
        }
        allContactsJson {
          edges {
            node {
              email
              phone
              phone_lv
              linkedin
              facebook
              name
              year
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);
